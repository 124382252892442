import AppLayout from 'layout/AppLayout';
import './Loading.scss';

type Props = {
  message?: string;
  useLayout?: boolean;
};

const Loading = ({ message = 'Loading...', useLayout = true }: Props) => {
  if (useLayout) {
    return (
      <AppLayout>
        <div className="app-loading">
          <img className="loader" src="/img/loading.gif" alt={message}/>
        </div>
      </AppLayout>
    );
  } else {
    return (
      <div className="app-loading">
        <img className="loader" src="/img/loading.gif" alt={message}/>
      </div>
    );
  }

};

export default Loading;
