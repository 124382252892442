import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import OrganizationHeader from 'components/OrganizationHeader';
import './style.scss';

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
};

const AboutUsPopUp = ({ isOpen, toggleModal }: Props) => {

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} className="about-us-popup">
        <ModalHeader toggle={toggleModal}>Community organization details</ModalHeader>
        <ModalBody>
          <div className="form-wrap">
            <OrganizationHeader />
          </div>
          <div className="submit-wrap submit-wrap-sticky">
            <button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>Close</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AboutUsPopUp;
