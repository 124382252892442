import { ComponentType, LazyExoticComponent } from 'react';
import { lazyWithRetry } from 'services';

export type AppRoute = {
  path: string;
  component: LazyExoticComponent<ComponentType>;
};

export const ROUTES = Object.freeze({
  OPPORTUNITIES: '/opportunities',
  PROGRAMS: '/programs',
  OPPORTUNITY_PROGRAMS: '/opportunity-programs/:jobId',
  OPPORTUNITY_PROGRAMS_PAGE: '/opportunity-programs',
  EXPIRED_LINK_PAGE: '/expired-link',
});

const routes: Array<AppRoute> = [
  {
    path: ROUTES.OPPORTUNITIES,
    component: lazyWithRetry(() => import('pages/opportunities')),
  },
  {
    path: ROUTES.PROGRAMS,
    component: lazyWithRetry(() => import('pages/programs')),
  },
  {
    path: ROUTES.OPPORTUNITY_PROGRAMS,
    component: lazyWithRetry(() => import('pages/opportunity-programs')),
  },
  {
    path: ROUTES.EXPIRED_LINK_PAGE,
    component: lazyWithRetry(() => import('pages/expired-link')),
  },
];

export default routes;
