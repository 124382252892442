import { AppDispatch } from 'app/store';
import axios, { AxiosInstance, AxiosRequestConfig, Method, ResponseType } from 'axios';
import fileDownload from 'js-file-download';

type AxiosCustomRequestConfig = AxiosRequestConfig & {};

type RequestOptions = {
  params?: object;
  data?: object;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: any) => void;
  signal?: AbortSignal;
  contentTypes?: string;
};

const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const REQUEST_TIMEOUT_IN_MILLISECONDS: number = 30000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let dispatch: AppDispatch;

export const injectDispatch = (_dispatch: AppDispatch) => {
  dispatch = _dispatch;
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: REQUEST_TIMEOUT_IN_MILLISECONDS,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error);
  },
);

export async function client<ResponseData>(
  method: Method,
  endpoint: string,
  options: RequestOptions = {},
): Promise<ResponseData> {
  try {
    const response = await axiosInstance.request<ResponseData>({
      method,
      url: endpoint,
      responseType: options.responseType,
      params: options.params,
      data: options.data,
      onUploadProgress: options.onUploadProgress,
      signal: options.signal,
      headers: { 'Content-Type': options.contentTypes ? options.contentTypes : 'application/json' },
    } as AxiosCustomRequestConfig);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function download(
  endpoint: string,
  options: RequestOptions = {},
) {
  try {
    const response = await axiosInstance.request({
      method: 'POST',
      url: endpoint,
      responseType: 'blob',
      params: options.params,
      data: options.data,
      headers: { 'Content-Type': 'application/json' },
    } as AxiosCustomRequestConfig);

    const contentDisposition = response.headers['content-disposition'];
    const matchHeader = contentDisposition.match(/filename="(.*)"/);
    const fileName = matchHeader ? matchHeader[1] : 'filename';
    fileDownload(response.data, fileName, response.headers['content-type']);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      
      const blobError = err.response.data as Blob;
      const error = new Error();
      error.message = JSON.parse(await blobError.text()).error;

      return Promise.reject(error);
    }
    return Promise.reject(err);
  }
}

export const withFormDataRequest = (data: object): RequestOptions => ({
  data,
  contentTypes: 'multipart/form-data',
});

export const withDataRequest = (data: object): RequestOptions => ({
  data,
});

export const withParamsRequest = (params: object): RequestOptions => ({
  params,
});

client.get = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
) {
  return client<ResponseData>('GET', endpoint, options);
};

client.post = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
) {
  return client<ResponseData>('POST', endpoint, options);
};

client.put = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
) {
  return client<ResponseData>('PUT', endpoint, options);
};

client.delete = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
) {
  return client<ResponseData>('DELETE', endpoint, options);
};
