import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import programsAPI from 'api/programs';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { Paging } from 'api/types';
import { ProgramsState, GetPublicJobBoardProgramsTableFilters, PublicJobBoardProgramsFiltersData, PublicJobBoardProgramTableView } from 'features/programs/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';

export const getPublicJobBoardTable = createAsyncThunk<TableDataResponse<PublicJobBoardProgramTableView>, void, RejectWithMessage>(
  'programs/get-public-job-board-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      return await programsAPI.getPublicJobBoardTable(state.programs.requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPublicJobBoardOpportunityTable = createAsyncThunk<TableDataResponse<PublicJobBoardProgramTableView>, string, RejectWithMessage>(
  'programs/get-public-job-board-opportunity-table',
  async (jobId, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      return await programsAPI.getPublicJobBoardOpportunityTable(state.programs.requestData, jobId);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPublicJobBoardFiltersData = createAsyncThunk<PublicJobBoardProgramsFiltersData, void, RejectWithMessage>(
  'programs/get-public-job-board-filters-data',
  async (_, { rejectWithValue }) => {
    try {
      return await programsAPI.getPublicJobBoardFiltersData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: ProgramsState = {
  status: 'idle',
  requestData: {
    filters: {},
    paging: {
      page: 1,
      pageSize: 20,
    },
  },
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    filtersData: null,
  },
  error: null,
};

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData! };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData!.paging.page = action.payload.page;
      state.requestData!.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<GetPublicJobBoardProgramsTableFilters>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialState.requestData.paging;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicJobBoardTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getPublicJobBoardTable.fulfilled, (state, action: PayloadAction<TableDataResponse<PublicJobBoardProgramTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getPublicJobBoardTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getPublicJobBoardOpportunityTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getPublicJobBoardOpportunityTable.fulfilled, (state, action: PayloadAction<TableDataResponse<PublicJobBoardProgramTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getPublicJobBoardOpportunityTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getPublicJobBoardFiltersData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getPublicJobBoardFiltersData.fulfilled, (state, action: PayloadAction<PublicJobBoardProgramsFiltersData>) => {
      state.status = 'fulfilled';
      state.data.filtersData = action.payload;
      state.error = null;
    });
    builder.addCase(getPublicJobBoardFiltersData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { resetRequestData, setPage, setFilters, resetTableData } = programsSlice.actions;
export const programsReducer = programsSlice.reducer;
