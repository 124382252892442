import { useState } from 'react';
import AboutUsPopUp from 'components/AboutUsPopUp';
import './style.scss';

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleModal = () => { setIsOpen(value => !value); };

  return (
    <>
      <button className="btn button-about-us" onClick={toggleModal}>
        About
      </button>
      <AboutUsPopUp isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
};

export default AboutUs;
