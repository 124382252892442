import './style.scss';
import SvgIcon from 'components/SvgIcon';
import AppLayout from 'layout/AppLayout';

const PageNotFound = () => {
  return (
    <AppLayout>
      <div className="container page-not-found-align">
        <div className="logo-margin-bottom">
          <SvgIcon className="logo" name="logo" />
        </div>
        <div>
          <SvgIcon className="page-not-found" name="page-not-found" />
        </div>
        <div>
          <h3>Oops! 404: Page not found!</h3>
          <p>We're sorry the page you're looking for floated away</p>
          <a className="take-me-back-link" href="/">Take me back to my account</a>
        </div>
      </div>
    </AppLayout>
  );
};

export default PageNotFound;
