import SVG from 'react-inlinesvg';

type Props = {
  name: string;
  title?: string;
  className?: string;
  onClick?: (event: any) => void;
};

const SvgIcon = ({ name, title, onClick, className = '' }: Props) => {
  return (
    <SVG
      onClick={onClick}
      cacheRequests={true}
      className={`svg-icon ${className}`.trim()}
      src={`/svg/${name}.svg`}
      uniqueHash="nkGedVx5"
      title={title}
      uniquifyIDs={true}
    />
  );
};

export default SvgIcon;
