import { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes, { AppRoute, ROUTES } from 'app/routes';
import Loading from 'components/Loading';
import './App.scss';
import PageNotFound from 'pages/page-not-found';
import AppLayout from 'layout/AppLayout';
import { useAppDispatch, useAppSelector } from './hooks';
import { getData } from 'features/organizations-data/organization-data';

const App = () => {
  const dispatch = useAppDispatch();
  const { status, organization } = useAppSelector(state => state.organizationData);
  const isLoading = useMemo(() => status === 'pending', [status]);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  if (isLoading) {
    return <Loading />;
  } else if (!organization) {
    return <PageNotFound />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map((route: AppRoute) => {
          const { component: Component, ...rest } = route;
          return <Route key={route.path} element={<AppLayout><Component /></AppLayout>} {...rest} />;
        })}
        <Route path="/" element={<Navigate to={ROUTES.OPPORTUNITIES} replace={true} />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
