import ScrollToTop from 'components/ScrollToTop';
import Header from 'layout/common/Header';
import Footer from 'layout/common/Footer';
import { useEffect, useState } from 'react';

export type Props = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const [layoutStyle, setLayoutStyle] = useState<string>('');

  const handleResize  = () => {
    if (window.innerWidth > 767) {
      setLayoutStyle('desktop');
    } else {
      setLayoutStyle('mobile');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className={`app app-layout-full ${layoutStyle} app-layout-authorized`.trim()}>
        <div className={'app-content container-fluid'}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppLayout;
