import { OrganizationPublicJobBoardData } from 'features/organizations-data/types';
import { client, withDataRequest } from 'utils/client';
import { JobShareLink } from 'pages/opportunities/components/ShareModal/types';

const organizationsAPI = {
  getData() {
    return client.get<OrganizationPublicJobBoardData>('/organizations/data');
  },
  getShareLinkToken(programId: string, userId?: string) {
    if (userId) {
      return client.post<{ token: string }>(`/organizations/get-share-link-token/?userId=${userId}`, withDataRequest({ programId, joiningSource: 'SharedOpportunityLink' }));
    } else {
      return client.post<{ token: string }>('/organizations/get-share-link-token', withDataRequest({ programId, joiningSource: 'CommunityPublicJobBoard' }));
    }
  },
  getJobShareLink(jobId: string) {
    return client.get<JobShareLink>(`/organizations/${jobId}/job-share-link`);
  },
};

export default organizationsAPI;
