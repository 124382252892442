import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import opportunityAPI from 'api/opportunities';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { OpportunitiesState, PublicJobBoardOpportunityTableView, GetPublicJobBoardOpportunitiesTableFilters, OrganizationJobDetails } from './types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { Paging } from 'api/types';

export const getOpportunities = createAsyncThunk<TableDataResponse<PublicJobBoardOpportunityTableView>, void, RejectWithMessage>(
  'opportunities/get-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      return await opportunityAPI.getOpportunities(state.opportunities.requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getPublicJobBoardOpportunity = createAsyncThunk<PublicJobBoardOpportunityTableView, string, RejectWithMessage>(
  'opportunities/get',
  async (jobId, { rejectWithValue }) => {
    try {
      return await opportunityAPI.getPublicJobBoardOpportunity(jobId);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getPublicJobDetails = createAsyncThunk<OrganizationJobDetails, string, RejectWithMessage>(
  'opportunities/get-details',
  async (jobId, { rejectWithValue }) => {
    try {
      return await opportunityAPI.getPublicJobDetails(jobId);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: OpportunitiesState = {
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    selectedOpportunity: null,
    details: null,
  },
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
    filters: {},
  },
  status: 'idle',
  error: null,
};

const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<GetPublicJobBoardOpportunitiesTableFilters>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialState.requestData.paging;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOpportunities.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getOpportunities.fulfilled, (state, action: PayloadAction<TableDataResponse<PublicJobBoardOpportunityTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getOpportunities.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getPublicJobBoardOpportunity.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getPublicJobBoardOpportunity.fulfilled, (state, action: PayloadAction<PublicJobBoardOpportunityTableView>) => {
      state.status = 'fulfilled';
      state.data.selectedOpportunity = action.payload;
      state.error = null;
    });
    builder.addCase(getPublicJobBoardOpportunity.rejected, (state) => {
      state.status = 'rejected';
    });

    builder.addCase(getPublicJobDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getPublicJobDetails.fulfilled, (state, action: PayloadAction<OrganizationJobDetails>) => {
      state.status = 'fulfilled';
      state.data.details = action.payload;
      state.error = null;
    });
    builder.addCase(getPublicJobDetails.rejected, (state) => {
      state.status = 'rejected';
    });
  },
});

export const { resetRequestData, setPage, setFilters, resetTableData } = opportunitiesSlice.actions;
export const opportunitiesReducer = opportunitiesSlice.reducer;
