import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { organizationDataReducer } from 'features/organizations-data/organization-data';
import { opportunitiesReducer } from 'features/opportunities';
import { programsReducer } from 'features/programs';

const appReducer = combineReducers({
  organizationData: organizationDataReducer,
  opportunities: opportunitiesReducer,
  programs: programsReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  return appReducer(state, action);
};

export default rootReducer;
