import { client, withDataRequest } from 'utils/client';
import { GetPublicJobBoardProgramsTable, PublicJobBoardProgramTableView, PublicJobBoardProgramsFiltersData } from 'features/programs/types';
import { TableDataResponse } from 'features/types';

const programsAPI = {
  getPublicJobBoardTable(params: GetPublicJobBoardProgramsTable) {
    return client.post<TableDataResponse<PublicJobBoardProgramTableView>>('/programs/get-table', withDataRequest(params));
  },
  getPublicJobBoardOpportunityTable(params: GetPublicJobBoardProgramsTable, jobId: string) {
    return client.post<TableDataResponse<PublicJobBoardProgramTableView>>(`/programs/get-opportunity-table/${jobId}`, withDataRequest(params));
  },
  getPublicJobBoardFiltersData() {
    return client.get<PublicJobBoardProgramsFiltersData>('/programs/filters-data');
  },
};

export default programsAPI;
