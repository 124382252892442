import './Footer.scss';
import { PRIVACY_POLICY_URL, SUBSCRIPTION_SERVICES_URL, TERMS_OF_USE_URL } from 'utils/consts';

const AppFooter = () => (
  <footer className="app-footer">
    <div className="container-fluid">
      <div className="copyright">
        <p>&copy; {new Date().getFullYear()} WorkerSpring, Inc. All rights reserved.</p>
      </div>
      <div className='website-links'>
        <p className='website-link'>
          <a href={PRIVACY_POLICY_URL} rel="noreferrer" target="_blank">Privacy Policy</a>
        </p>
        <p className='website-link'>
          <a href={TERMS_OF_USE_URL} rel="noreferrer" target="_blank">Terms of Use</a>
        </p>
        <p className="website-link">
          <a href={SUBSCRIPTION_SERVICES_URL} rel="noreferrer" target="_blank">Subscription Services</a>
        </p>
      </div>
    </div>
  </footer>
);

export default AppFooter;
