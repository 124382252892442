import { Link, NavLink } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, Collapse } from 'reactstrap';
import './Header.scss';
import SvgIcon from 'components/SvgIcon';
import { useAppSelector } from 'app/hooks';
import { ROUTES } from 'app/routes';
import { useState } from 'react';
import { getExternalLink } from 'utils/links';
import AboutUs from 'components/AboutUs';

const AppHeader = () => {
  const { organization } = useAppSelector(state => state.organizationData);
  const [navbarIsOpen, setNavbarIsOpen] = useState(false);
  const toggleNavbar = () => setNavbarIsOpen(value => !value);
  
  return (
    <Navbar className="navbar-header" expand="md" fixed="top">
      <button className="navbar-toggler" onClick={toggleNavbar}>
        <SvgIcon name="sidebar/toggle-sidebar" />
      </button>
      <NavbarBrand to="/" href={organization?.webAddress ? getExternalLink(organization.webAddress) : ''} tag={organization?.webAddress ? 'a' : Link}>
        {organization?.logo ? <img alt="Logo" src={organization.logo} /> : <SvgIcon name="Logo" title="WorkerSpring" />}
      </NavbarBrand>
      <Collapse isOpen={navbarIsOpen} navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <NavLink className="nav-link btn btn-link" to={ROUTES.OPPORTUNITIES} onClick={() => setNavbarIsOpen(false)}>Jobs</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link btn btn-link" to={ROUTES.PROGRAMS} onClick={() => setNavbarIsOpen(false)}>Programs</NavLink>
          </NavItem>
          <NavItem>
            <AboutUs />
          </NavItem>
        </Nav>
        <Nav navbar>
          <NavItem>
            <Link className="nav-link btn btn-primary" to={ROUTES.PROGRAMS} onClick={() => setNavbarIsOpen(false)}>Join as a job seeker</Link>
          </NavItem>
          {organization?.isAnyAllowHiringProgram &&
            <NavItem>
              <a className="nav-link btn btn-secondary" href={process.env.REACT_APP_HIRE_URL} rel="noreferrer" target="_blank">Hire job seekers</a>
            </NavItem>
          }
          <NavItem>
            <a className="nav-link btn btn-link" href={process.env.REACT_APP_SIGN_IN_URL} rel="noreferrer" target="_blank">Sign in</a>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppHeader;
