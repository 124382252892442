import React, { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'app/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'app/store';
import { injectDispatch } from 'utils/client';
import Loading from 'components/Loading';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ToastContainer } from 'react-toastify';

const BUGSNAG_KEY = process.env.REACT_APP_BUGSNAG_KEY;
const BUGSNAG_ENV = process.env.REACT_APP_BUGSNAG_ENV;
const isBugsnagEnabled = Boolean(BUGSNAG_KEY && BUGSNAG_ENV);

if (isBugsnagEnabled) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY!,
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: BUGSNAG_ENV,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = isBugsnagEnabled
  ? Bugsnag.getPlugin('react')!.createErrorBoundary(React)
  : Fragment;

injectDispatch(store.dispatch);

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate 
          loading={<Loading />} 
          persistor={persistor}
        >
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </PersistGate>
      </BrowserRouter>
      <ToastContainer
        autoClose={5000}
        className="toast-alert"
        position="top-center"
        hideProgressBar={true}
      />
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
