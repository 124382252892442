export const VALIDATION_REQUIRED_MESSAGE = 'This field is required';
export const VALIDATION_INVALID_EMAIL_MESSAGE = 'Please enter a valid email';
export const VALIDATION_INVALID_PHONE_MESSAGE = 'Please enter a valid phone number';
export const VALIDATION_MAX_LENGTH_MESSAGE = (field: string): string => `${field} must be shorter than or equal to \${max} characters`;
export const VALIDATION_FIELD_REQUIRED_MESSAGE = (field: string): string => `${field} is required`;
export const PRIVACY_POLICY_URL = 'https://workerspring.com/privacy-policy/';
export const WRONG_FILE_SIZE = 'File too large';
export const VALIDATION_MIN_MESSAGE = (field: string, number: number | string): string => `${field} must be greater than or equal to ${number}`;
export const VALIDATION_MAX_MESSAGE = (field: string, number: number | string): string => `${field} must be less than or equal to ${number}`;
export const FULL_ADDRESS_INVALID_MESSAGE = 'Cannot get location. Please check the entered address';
export const ALLOWED_RESUME_CONTENT_TYPES = { 'application/msword': ['.doc'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], 'application/pdf': ['.pdf'] };
export const TERMS_OF_USE_URL = 'https://workerspring.com/terms/';
export const DEFAULT_OPPORTUNITY_FILTER_RADIUS = 30;
export const DROPZONE_IMAGE_ACCEPT = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };
export const DATE_TIME_FORMAT = 'PP \'at\' p';
export const SUBSCRIPTION_SERVICES_URL = 'https://workerspring.com/subscription-services-agreement/';
