import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import organizationsAPI from 'api/organizations';
import { RootState } from 'app/root-reducer';
import { RejectWithMessage } from 'features/types';
import { OrganizationDataState, OrganizationPublicJobBoardData } from './types';
import { getErrorMessage } from 'utils/error';

export const getData = createAsyncThunk<OrganizationPublicJobBoardData, void, RejectWithMessage>(
  'organization-data/get',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationsAPI.getData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: OrganizationDataState = {
  organization: null,
  status: 'idle',
  error: null,
};

const organizationDataSlice = createSlice({
  name: 'organization-data',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(getData.fulfilled, (state, action: PayloadAction<OrganizationPublicJobBoardData>) => {
      state.organization = action.payload;
      state.status = 'fulfilled';
      state.error = null;
    });

    builder.addCase(getData.rejected, (state, action) => {
      state.organization = null;
      state.status = 'rejected';

      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
});

export const selectOrganization = (state: RootState): OrganizationPublicJobBoardData | null => state.organizationData.organization;

export const organizationDataReducer = organizationDataSlice.reducer;
