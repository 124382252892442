import { useAppSelector } from 'app/hooks';
import './style.scss';
import { getExternalLink } from 'utils/links';

const OrganizationHeader = () => {
  const { organization } = useAppSelector(state => state.organizationData);
  
  return <>
    <div className="page-wrap">
      <div className="organization-header">
        <div className="row">
          <div className="col-auto d-none d-md-flex">
            {organization?.logo && <img className="organization-logo" alt="Logo" src={organization.logo} />}
          </div>
          <div className="col">
            <h4 className="page-title">
              {organization?.name}
              {organization?.webAddress && <p className="web-link"><a href={getExternalLink(organization.webAddress)} rel="noreferrer" target="_blank">{organization.webAddress}</a></p>}
            </h4>
            <span className="page-instructions">{organization?.pageInstructions}</span>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default OrganizationHeader;
